import React from "react";
import Page from "../../components/Page";
import image from "../../images/thinking.jpg"
import { motivationQoutes } from "../../data/qoutes";
import QoutesPage from "../../components/Citat/QoutesPage";
import Qoute from "../../components/Citat/Qoute";

export default function Citat() {
  const title = "De bästa citaten för att bli motiverad"

  return (
    <>
      <Page
        title={title}
        description="Bli motiverad med några av de bästa citaten från flera framgångsrika individer såsom Walt Disney, Steve Jobs och många fler."
      >
        <QoutesPage title={title}>
          <img src={image} className="qouteImage" alt="citat livet" />
          <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8 mt-6">{title}</h1>
          <p>Här kommer de allra bästa citaten kring att bli motiverad. Hitta din inre drivkraft.</p>
          <div className="qoutesList">
            {motivationQoutes.map((q, index) => {
              return <Qoute key={index} text={q.qoute} author={q.author} number={index + 1} />
            })}
          </div>

        </QoutesPage>
      </Page>
    </>
  );
}
